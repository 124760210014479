import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { toast } from 'react-toastify'
import { Link } from "gatsby"

import telegramIcon from '../images/telegram.svg'
import facebookIcon from '../images/facebook.svg'
import instagramIcon from '../images/instagram.svg'
import rainforest from '../images/chappi/logo_rainfor.png'

// import "../styles/components/_footer.scss"


const Footerfr = () => {
  const [value, setValue] = useState('')

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(value)
    if (value) {
      addToMailchimp(value)
        .then((data) => {
          if (data.result === 'error') {
            toast("Quelque chose n'a pas fonctionné. Veuillez réessayer.")
          }

          if (data.result === 'success') {
            toast('Merci beaucoup - Nous vous tiendrons au courant.', )
            setValue('')
          }
        })
    }
  }

  return (
    <footer>
      <section>
      
        <div className="address">
          <h3 style={{fontFamily:"gill-sans-nova", textTransform:'uppercase', fontSize:'25px'}}>Daisy International Switzerland GmbH</h3>
          <span >SEESTRASSE 215</span>
          <span style={{ textTransform:'uppercase'}}>
          8810 Horgen
          </span>
          {/* <span><a href="mailto:info@fahr-erlebnis.ch">info@fahr-erlebnis.ch</a></span>
          <span> Büro & Anfragen: <a href="tel:0447500101">044 750 01 01</a></span>

          <span> Hofladen: <a href="tel:+41794193333">079 419 33 33</a></span> */}
          <br></br>
          <span style={{color:'black',fontFamily:"gill-sans-nova"}}><Link to="/impressum_francais">IMPRESSUM</Link></span>

        </div>
        <div className="Newsletter">
          {/* <p>Bleiben Sie auf dem laufenden</p> */}
          <form onSubmit={onSubmit}>
            <h3 style={{fontFamily:"gill-sans-nova", fontSize:'25px'}}>NEWSLETTER</h3>
            <p className='footerNewsDesc' >C'est avec plaisir que nous vous tiendrons au courant du lancement de la prévente et de l'entrée sur le marché en Suisse ainsi 
            que des nouvelles de notre projet au Vietnam. <br/> Pour vous inscrire, veuillez saisir vos coordonnées ici: 
             </p>
            
            <div className="inputBar">



            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              name="message"
              type="email"
              placeholder="e-mail"
            />
            <button type="submit">
              <img src={telegramIcon} alt="telegram" />
            </button>
            </div>


            {/* <div className="social-links">
              <a target="_blank" href="https://www.instagram.com/fahr_erlebnis">
                <img src={instagramIcon} alt="instagram" />
              </a>
              <a target="_blank" href="https://www.facebook.com/FahrErlebnis">
                <img src={facebookIcon} alt="facebook" />
              </a>
            </div> */}
          </form>
         
          
        </div>
        {/* <div className='sponsor'>
          <a href="https://www.rainforest-alliance.org/" target="_blank" rel="noopener noreferrer">
          <img className='sponsor-image' src={rainforest} /></a> 
        </div> */}
      </section>
      {/* <p style={{ marginBottom: 0, paddingBottom: '10px'}}>Eine Webseite von 
      <a href="https://Gewerbe-Seiten.ch" target="_blank" rel="noopener noreferrer">Gewerbe-Seiten.ch</a> 
      </p> */}
    </footer>
  )
}

export default Footerfr;