import React from "react"
import { Parallax } from "react-parallax"

import { Container, Row, Col } from "reactstrap"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { parseOptions } from '../utils/praseOptions';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import HeroFrench from "../components/herofr"
import { graphql, Link } from 'gatsby'
import MenuErlebnisse from '../components/menu/menu'
import AktuellMessage from '../components/aktuellMessage'
import LeafletMap from '../components/leafletMap'
import Footerfr from '../components/footerfr'
import useWindowSize from '../utils/useWindowsSize'


import arabica from "../images/chappi/arabicaa.png"
import rob from "../images/chappi/robusta_chappi.png"
import blend from "../images/chappi/blend_chappi.png"

import Parallax1 from '../images/chappi/02.jpg'
import Parallax1Mobile from '../images/chappi/mob/02.jpg'

// import Parallax2 from '../images/hofladen_fahr-erlebnis.jpeg'
// import Parallax2Mobile from '../images/parallax2-mobile.jpg'

import Parallax3 from '../images/chappi/03.jpg'
import Parallax3Mobile from '../images/chappi/mob/03.jpg'

import Parallax4 from '../images/chappi/04.jpg'
import Parallax4Mobile from '../images/chappi/mob/04.jpg'

import Parallax5 from '../images/chappi/05.jpg'
import Parallax5Mobile from '../images/chappi/mob/05.jpg'

import Parallax6 from '../images/chappi/06.jpg'
import Parallax6Mobile from '../images/chappi/mob/06.jpg'

import Parallax7 from '../images/chappi/07.jpg'
import Parallax7Mobile from '../images/chappi/mob/07.jpg'

import Parallax8 from '../images/chappi/08.jpg'
import Parallax8Mobile from '../images/chappi/mob/08.jpg'

import Parallax9 from '../images/chappi/09.jpg'
import Parallax9Mobile from '../images/chappi/09.jpg'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const IndexPage = ({ data: {
  coffeeShop,
  // allContentfulContainerTexteHomepage,
  // allContentfulAktuellBannerFahrErlebnisCh
}}) => {
  // const content = allContentfulContainerTexteHomepage?.nodes?.[0]
  const { width } = useWindowSize()

  return (
    <Layout>
    <SEO title="Home" />
    
    <HeroFrench fullScreen subtitle="Café de qualité du Vietnam" image={'../images/Landing_Trotte_AD_6512_rgb.jpg'} />

    {/* {allContentfulAktuellBannerFahrErlebnisCh?.nodes && (
      <AktuellMessage {...allContentfulAktuellBannerFahrErlebnisCh?.nodes[0]} />
    )} */}

    <Container style={{ marginTop: '60px' }}>
      <Row>
        <Col xl="12" lg="12">
          {/* <p className="aktuell">Aktuell: {allContentfulAktuell?.nodes?.[0]?.aktuell} </p>
          <br></br> */}
          <p className="cont-desc-1" style={{fontWeight:'700', fontSize:'3rem'}}>
          


          Coming soon – stay tuned..!  <br/>



          </p>
        </Col>
      </Row>
    </Container>

    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax1Mobile : Parallax1}
      bgImageAlt='Aktuell'
    />

    {/* <div className="parallax1"></div> */}

    {/* <MenuErlebnisse /> */}

    {/* <div className="parallax2"></div> */}

    {/* <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax2Mobile : Parallax2}
      bgImageAlt='Aktuell'

    /> */}

    <Container>
      <Row>
        <Col xl="6" lg="6">
        <h2 className="sec-titel" >  Café de qualité du Vietnam - Équitable. Social. Durable </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Le projet suisse de cœur dans les hauts plateaux du centre du Vietnam, initié
           par la Vietnamienne Thao Nguyen et le Suisse Marc Zihlmann, a déjà remporté plusieurs
            prix pour son concept d'entreprise socialement
           responsable et durable. Thao et Marc sont un couple très engagé dans le commerce du café alternatif au Vietnam, pays du café.  
            {/* {documentToReactComponents(content.hofladenText.raw, parseOptions)} */}
          </p>
        </Col>
      </Row>
    </Container>
        
    {/* <div className="parallax3"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax3Mobile : Parallax3}
      bgImageAlt='Aktuell'

    />

    <Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">Certifié Rainforest Alliance</h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Les cafés de qualité certifiés selon les normes de la Rainforest Alliance sont
           cultivés par les agriculteurs locaux avec beaucoup de passion, soigneusement cueillis à la main et lentement torréfiés
           dans un tambour traditionnel. Une expérience gustative inoubliable qui peut créer une forte dépendance.
            {/* {documentToReactComponents(content.landwirtschaftText.raw, parseOptions)} */}
          </p>
          {/* <a className="menu-link" href="https://fahr-landwirtschaft.ch" rel="noopener noreferrer">mehr dazu</a> */}

        </Col>
      </Row>
    </Container>

    {/* <div className="parallax4"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax4Mobile : Parallax4}
      bgImageAlt='Aktuell'

    />

    <Container>
      <Row>
        <Col xl="6" lg="6">

        <h2 className="sec-titel" >Commerce direct </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          De la ferme à la tasse: c'est à 1'500 mètres d'altitude, en bordure du parc national de Bidoup Nui 
          Ba, que nos cafés arabica sont cultivés et transformés jusqu'au produit final torréfié. Grâce à 
          une étroite collaboration avec plus de 40 cultivateurs de café de l'ethnie K'Ho, tous membres de
           la coopérative d'arabica des montagnes Chappi, les exigences de qualité maximale peuvent être mises
           en œuvre de manière optimale à tous les niveaux de la chaîne de création de valeur.
            {/* {documentToReactComponents(content.trotteText.raw, parseOptions)} */}
          </p>
          {/* <a className="menu-link" href="https://fahr-event.ch" rel="noopener noreferrer">mehr dazu</a> */}

        </Col>
      </Row>
    </Container>

    {/* <div className="parallax5"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax5Mobile : Parallax5}
      bgImageAlt='Aktuell'

    />

    <Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">
          Valeur ajoutée totale dans le pays d'origine
             </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Dans une région pauvre et peu structurée des hauts plateaux vietnamiens,
           le projet a permis de créer de nombreux nouveaux emplois - dans la transformation du café,
            grâce à l'exploitation des bungalows Chappi Mountains avec leur
           propre restaurant et café, et grâce à notre offre d'activités d'écotourisme et de circuits de découverte du café.
            {/* {documentToReactComponents(content.gastronomieText.raw, parseOptions)} */}
          </p>
            {/* <Link className="menu-link" to="/gastronomie"> mehr dazu</Link> */}
        </Col>
      </Row>
    </Container>

    {/* <div className="parallax6"></div> */}
   

<Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax6Mobile : Parallax6}
      bgImageAlt='Aktuell'

    />

<Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">
          Plusieurs fois récompensé
             </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Nos efforts pour combiner une qualité de produit élevée avec des pratiques commerciales 
          socialement responsables et un mode de production durable ont été récompensés par plusieurs prix: 
          Vietnam National Golden Brand, Special Product from Dalat, One Commune One Product et Special Rural Product.
            {/* {documentToReactComponents(content.seminarrumeText.raw, parseOptions)} */}
          </p>
            {/* <Link className="menu-link" to="/seminar-raum"> mehr dazu</Link> */}
        </Col>
      </Row>
    </Container>

    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax7Mobile : Parallax7}
      bgImageAlt='Aktuell'

    />

    {/* <div className="parallax6"></div> */}
  


    <Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">
          Coming soon..
            </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Nos produits seront commercialisés en Suisse à partir de fin octobre, et nous prendrons 
          vos précommandes à partir du mois d'août afin que le café arrive chez vous avec un maximum de 
          fraîcheur. Avec chaque paquet et chaque tasse, vous soutenez notre projet et donc les cultivateurs de café et leurs familles!
          </p>

        </Col>
      </Row>
    </Container>

    {/* <div className="parallax7"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax8Mobile : Parallax8}
      bgImageAlt='Aktuell'

    />


<Container className="prod-cont" style={{paddingTop:'20px'}}>
      <Row>
        <Col xl="6" lg="6" md="7" sm="12" className="col1-prod">
          <img className="prod-img" src={arabica} />
        </Col>
        
        <Col xl="6" lg="6" md="5" sm="12" className="col2-prod">
        <h2 className="prod-titel">Arabica - Doux, fleuri, fruité</h2>
          <p className="prod-desc">
          Notre Arabica - un mélange de variétés cueillies à la main -
           garantit, grâce à son équilibre subtil, une expérience gustative
            sensuelle à tout moment de la journée - le plaisir à l'état pur!
          </p>
          {/* <Link className="menu-link" to="/das-team"> mehr über uns</Link> */}

        </Col>
      </Row>
    </Container>
    <Container className="prod-cont">
      <Row>
        <Col xl="6" lg="6" md="7" sm="12" className="col1-prod">
          <img className="prod-img" src={rob} />
        </Col>
        
        <Col xl="6" lg="6" md="5" sm="12" className="col2-prod">
        <h2 className="prod-titel">Robusta - Puissant, épicé, chocolaté</h2>
          <p className="prod-desc">
          Notre Robusta - un café de petit-déjeuner fort et intense avec une portion supplémentaire de caféine pour commencer 
          la journée de manière optimale! Et prédestiné à donner une note plus forte à n'importe quel café Arabica en l'ajoutant.
          </p>
          {/* <Link className="menu-link" to="/das-team"> mehr über uns</Link> */}

        </Col>
      </Row>
    </Container>
    <Container className="prod-cont">
      <Row>
        <Col xl="6" lg="6" md="7" sm="12" className="col1-prod">
          <img className="prod-img" src={blend} />
        </Col>
        
        <Col xl="6" lg="6" md="5" sm="12" className="col2-prod">
        <h2 className="prod-titel">Blend - Équilibré, fruité, chocolaté</h2>
          <p className="prod-desc">
          Notre mélange maison - une combinaison soigneusement équilibrée de cafés Arabica et
           Robusta qui fait battre plus vite le cœur de tout amateur de café - un moment de détente à tout moment de la journée!
          </p>
          {/* <Link className="menu-link" to="/das-team"> mehr über uns</Link> */}

        </Col>
      </Row>
    </Container>

    <Parallax
      strength={300}
      className="react-parallax-last"
      bgImage={width < 768 ? Parallax9Mobile : Parallax9}
      bgImageAlt='Aktuell'

    />


    {/* <section className="top-map">
      <span>Das Kloster Fahr an der Limmat</span>
      <br></br>
      <Link className="AnreiseLink" to="/kontakt">Anreise</Link>
    </section> */}

    {/* {typeof window !== 'undefined' &&
      <LeafletMap
      position={[47.4087048, 8.4393097]} // Your Coordinates
        zoom={15} // Zoom Level
        markerText={"Kloster Fahr"} // Icon text
      />
    } */}
    <Footerfr />
  </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
   
     gatsbyImageData(layout: FIXED)
    }
  }
`;

export const pageQuery = graphql`
  query {
    coffeeShop: file(relativePath: { eq: "Landing_TopTitelbild_AD_6672_rgb_web.jpg" }) {
      ...fluidImage
    }
    


  }
`;




export default IndexPage
